import React from "react";
import {Link} from "gatsby";
import '../scss/global.scss'
import {
    brochuresSection,
    grid,
    brochureCta,
    brochureCta__content,
    button,
    oilAndGas,
    renewables,
    alternative
} from '../scss-modules/brochures.module.scss';

export default function Brochures() {
    return (
        <section id="companyBrochures" className={brochuresSection}>
            <div className={` grid ${grid}`}>
                <a href={'../../brochures/performance-energy_Oil-Gas-Brochure-2024.pdf'} target="_blank"
                   rel="noopener noreferrer">
                    <div className={`column ${brochureCta}`}>
                        <img src={"../../ctas/oil-and-gas_brochure.jpg"} alt="Oil and Gas Brochure"/>
                        <div className={brochureCta__content}>
                            <h4>Oil & Gas</h4>
                            <span className={`button white arrow ${button}`}>
                                <span>Download Brochure</span>
                            </span>
                        </div>
                    </div>
                </a>

                <Link to={"/key-sectors/renewable-energy"}>
                    <div className={`column ${brochureCta}`}>
                        <img src={"../../ctas/renewable-energy_brochure.jpg"} alt="Renewable Energy Brochure"/>
                        <div className={brochureCta__content}>
                            <h4>Renewable Energy</h4>
                            <span className={`button white arrow ${button}`}>
                                <span>Visit Page</span>
                            </span>
                        </div>
                    </div>
                </Link>

                <Link to={"/key-sectors/alternative-energy"}>
                    <div className={`column ${brochureCta}`}>
                        <img src={"../../ctas/alternative-energy_brochure.jpg"} alt="Alternative Energy Brochure"/>
                        <div className={brochureCta__content}>
                            <h4>Alternate Energy</h4>
                            <span className={`button white arrow ${button}`}>
                                <span>Visit page</span>
                            </span>
                        </div>
                    </div>
                </Link>

            </div>
        </section>
    )
}