import React from "react";
import { Link } from "gatsby";
import "../scss/global.scss";
import {
  servicesGrid__section__cta,
  servicesGrid__section__cta__content,
  grid,
} from "../scss-modules/services-grid.module.scss";

export default function ServicesGrid() {
  return (
    <section id="servicesGrid">
      <div className="container">
        <div className={grid}>
          <Link to={"/services/personnel-supply"}>
            <div className={servicesGrid__section__cta}>
              <img
                src={"../../ctas/personnel-supply_cta.jpg"}
                alt={"Technical Personnel Supply Service"}
              />
              <div className={servicesGrid__section__cta__content}>
                <h4>Technical Personnel Supply</h4>
                <span className="button inline arrow-light">
                  <span style={{ color: "white" }}>Explore</span>
                </span>
              </div>
            </div>
          </Link>

          <Link to={"/services/equipment-brokerage"}>
            <div className={servicesGrid__section__cta}>
              <img
                src={"../../ctas/equipment-brokerage_cta.jpg"}
                alt={"Equipment Brokerage Service"}
              />
              <div className={servicesGrid__section__cta__content}>
                <h4>Equipment Brokerage</h4>
                <span className="button inline arrow-light">
                  <span style={{ color: "white" }}>Explore</span>
                </span>
              </div>
            </div>
          </Link>

          <Link to={"/services/equipment-brokerage"}>
            <div className={servicesGrid__section__cta}>
              <img
                src={"../../ctas/fuel-brokerage-cta.jpg"}
                alt={"Fuel Brokerage services"}
              />
              <div className={servicesGrid__section__cta__content}>
                <h4>Fuel Brokerage</h4>
                <span className="button inline arrow-light">
                  <span style={{ color: "white" }}>View details</span>
                </span>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
}
